import React from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import {Breadcrumbs, Loader} from 'ris/common';
import AppCard from '@crema/core/AppCard';
import {Autocomplete} from '@material-ui/lab';
import CurrentTicketComponent from "./currentTicket";

export default function View(data) {
  let {controller, state} = data;

  const TicketsList = (props) => {
    return (
      <Box pt={3}>
        {Array.isArray(props.tickets) ? props.tickets.map((ticket) => (
            <Grid container spacing={4} key={ticket.id}>
              <Grid item xs={7}>
                <Typography variant='h5'>
                  <a href={'/examination-ticket/' + ticket.uuid} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                    {ticket.code}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {ticket.status === 4 ? (
                  <Button
                    variant='contained'
                    className='button-primary'
                    onClick={() =>
                      controller.restoreExaminationTicket(ticket.id)
                    }
                  >
                    Вернуть в очередь
                  </Button>
                ) : (
                  <Typography variant='h5'>
                    {new Date(ticket.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ))
          : ''}
      </Box>
    )
  }

  return (
    <div className='registries-detail'>
      <Loader active={state.loading}/>
      {
        /* (RIS._me.active_queue && !RIS._me.active_queue.is_available) */ (
        <Breadcrumbs path={[{href: '/examinations', text: 'к списку'}]}/>
      )
      }
      <AppCard>
        <Grid container spacing={4}>
          <Grid item xs={6} style={{border: '1px solid gray'}}>
            <Box pb={3} style={{borderBottom: '1px solid gray'}}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Box>Моя специальность</Box>
                  {state.item.specialities ? (
                    <Autocomplete
                      disabled={state.doctorActive}
                      multiple
                      style={{width: '100%'}}
                      options={Object.values(state.item.specialities).map(item => item.title)}
                      value={state.mySpecialities || []}
                      freeSolo
                      onChange={(e, newValue) =>
                        controller.setState('mySpecialities', newValue || [])
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option}
                            {...getTagProps({index})}
                            key={index}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          style={{marginTop: '10px'}}
                          variant='outlined'
                        />
                      )}
                    />
                  ) : ''}

                  <Button
                    variant='contained'
                    className='button-primary'
                    style={{marginTop: 10}}
                    onClick={() =>
                      controller.setDoctorActive(state.doctorActive)
                    }
                    disabled={!(state.myOffice && state.mySpecialities && state.mySpecialities.length)}>
                    {state.doctorActive ? 'Выйти из очереди приема' : 'Войти в очередь приема'}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <>
                      <div>Мой кабинет</div>
                      <Select
                        disabled={state.doctorActive}
                        onChange={(v) =>
                          controller.setState('myOffice', v.target.value)
                        }
                        value={state.myOffice}
                        style={{width: '100%', marginBottom: '15px'}}>
                        <MenuItem value=''>Не выбрано</MenuItem>
                        {state.officesDictionary
                          ? state.officesDictionary.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                              {el.content}
                            </MenuItem>
                          ))
                          : ''}
                      </Select>
                    </>

                    {
                      (
                        <>
                          <Box>
                            <Box>Начало приёма</Box>
                            <Box>
                              {state.windowName ? state.windowName : ''}
                            </Box>
                            <Typography variant='h4' pl={3}>
                              {state.my_examination_start_time}
                            </Typography>
                          </Box>
                        </>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} pb={3} style={{borderBottom: '1px solid gray'}}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant={"body1"}>Среднее время приёма</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"subtitle1"}>{RIS._me.my_average_reception_time ?? '00:00:00'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"body1"}>Всего приёмов сегодня</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"subtitle1"}>{RIS._me.my_reception_count}</Typography>
                </Grid>
              </Grid>
            </Box>
            {
              state.doctorActive ? (
                <>
                  <Box mt={3}>
                    <Grid container spacing={4}>
                      <CurrentTicketComponent/>
                      <Grid item xs={6}>
                        {
                          RIS._me.my_process_wait_examination_ticket?.id !== undefined &&
                          (<>
                              <Box pt={2}>
                                <Button
                                  variant='contained'
                                  disabled={!state.repeatExaminationAvailable}
                                  className='button-primary'
                                  onClick={() =>
                                    controller.repeatExaminationTicket(RIS._me.my_process_wait_examination_ticket.id ?? RIS._me.my_process_process_examination_ticket.id,)
                                  }
                                  size="large"
                                  style={{backgroundColor: 'lightgreen', width: "100%", minHeight:"70px"}}
                                >
                                  Пришёл, начать приём
                                </Button>
                              </Box>
                              {/*<Box pt={2}>*/}
                              {/*  <Button*/}
                              {/*    variant='contained'*/}
                              {/*    className='button-primary'*/}
                              {/*    onClick={(e) =>*/}
                              {/*      // TODO при нажатии отправлять на распределение но не в этот кабинет*/}
                              {/*      // controller.resetTicket(*/}
                              {/*      //   RIS._me.current_ticket.id,*/}
                              {/*      // )*/}
                              {/*      alert('В разработке...')*/}
                              {/*    }*/}
                              {/*  >*/}
                              {/*    Не пришёл,<br/> пригласить следующего*/}
                              {/*  </Button>*/}
                              {/*</Box>*/}
                            </>
                          )
                        }
                        {
                          (RIS._me.my_process_process_examination_ticket?.id !== undefined || (RIS._me.my_process_wait_examination_ticket?.id === undefined && RIS._me.my_wait_wait_examination_ticket?.id !== undefined)) &&
                          (<Box pt={2}>
                            <Button
                              variant='contained'
                              className='button-primary'
                              disabled={!state.inviteNextExaminationTicketAvailable}
                              onClick={() => {
                                controller.inviteNextExaminationTicket();
                              }}
                              size="large"
                              style={{backgroundColor: 'lightgreen', width: "100%", minHeight:"70px"}}
                            >
                              {RIS._me.my_wait_wait_examination_ticket?.id ? 'Закончить прием и пригласить следующего' : 'Закончить прием'}

                            </Button>
                          </Box>)
                        }
                        {RIS._me.my_process_process_examination_ticket?.id !== undefined ?
                          <Box pt={2}>
                            <Button
                              disabled={!state.returnToQueueExaminationTicketAvailable}
                              variant='outlined'
                              color={"secondary"}
                              onClick={() =>
                                controller.returnToQueueExaminationTicket(RIS._me.my_process_process_examination_ticket.id)
                              }
                              size="large"
                              style={{backgroundColor: 'lightgrey', width: "100%", minHeight:"70px"}}
                            >

                              Вернуть в очередь<br/> без отметки о прохождении
                            </Button>
                          </Box> :
                          ''}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pt={25}>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Box>
                          <Box pb={2}>Следующий талон</Box>
                          <Typography variant='h3'>
                            {RIS._me.my_wait_wait_examination_ticket ? RIS._me.my_wait_wait_examination_ticket.code : ''}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                ''
              )
            }
          </Grid>
          <Grid item xs={6} style={{border: '1px solid gray'}}>
            <Box pt={3} pb={3} style={{borderBottom: '1px solid gray'}}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography variant='h4'>
                    {RIS._me.all_today_waiting}
                  </Typography>
                  Сейчас в очереди
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h4'>
                    {RIS._me.all_avg_waiting_time}
                  </Typography>
                  Ср. Время ожидания
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h4'>
                    {RIS._me.all_today_done}
                  </Typography>
                  Всего за сегодня
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Tabs
                value={state.tab}
                onChange={(e, v) => controller.setState('tab', v)}
                indicatorColor='primary'
                textColor='primary'
                aria-label='Tickets Tabs'
              >
                <Tab label='Моя очередь' value='my_tickets'/> {/* все кто сейчас конкретно распределен в очередь в данный кабинет */}
                <Tab label='Все кто должен пройти' value='my_tickets_future'/> {/* все талоны которым еще нужно будет пройти данный кабинет (без потеряшек) */}
                <Tab label='Все в очереди' value='all_tickets'/> {/* все талоны которые сейчас проходят любой медосмотр */}
                <Tab label='Уже прошли' value='my_tickets_done'/> {/* все талоны которые уже прошли данный сегодня кабинет */}
                <Tab label='Потеряшки' value='tickets_lost'/>
              </Tabs>
            </Box>
            {state.tab === 'my_tickets' ? (<TicketsList tickets={RIS._me.my_examination_tickets}/>) : ''}
            {state.tab === 'my_tickets_future' ? (<TicketsList tickets={RIS._me.my_tickets_future}/>) : ''}
            {state.tab === 'all_tickets' ? (<TicketsList tickets={RIS._me.all_examination_tickets}/>) : ''}
            {state.tab === 'my_tickets_done' ? (<TicketsList tickets={RIS._me.my_tickets_done}/>) : ''}
            {state.tab === 'tickets_lost' ? (<TicketsList tickets={RIS._me.lost_examination_tickets}/>) : ''}

          </Grid>
        </Grid>
      </AppCard>
    </div>
  );
}
